.switch-menu-modal {
  .button-group {
    > div {
      width: 50%;
    }
  }
  &.p-dialog .p-dialog-footer button {
    margin: 0;
  }
}
