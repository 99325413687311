@import '../../styles/variables';

.social-icons-curaleaf {
  .s-icon > a {
    svg {
      height: 36px;
      width: 36px;
      align-items: center;
      display: flex;
      &:hover {
        path {
          opacity: 0.5;
        }
      }
    }
    &.primary > svg {
      stroke: var(--primary);
      fill: var(--primary);
    }
    &.secondary > svg {
      stroke: var(--specialty-primary);
      fill: var(--specialty-primary);
    }
    &.small > svg {
      height: 20px;
      width: 20px;
    }
    &.normal > svg {
      height: 36px;
      width: 36px;
    }
  }
}
