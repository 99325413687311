@import '../../styles/variables';

.dispensary-title-banner {
  z-index: 5;
  height: 36px;
  position: relative;
  padding: 8px calc(var(--spacing-unit) * 2);
  @include media-breakpoint-down(sm) {
    padding: 6px var(--spacing-unit);
  }
  .clear-dispensary-button {
    background-color: transparent;
    border: none;
    display: inline-flex;
    align-items: center;
  }
  a {
    .selected-store {
      padding-inline: 5px;
    }
    &:hover {
      svg > path {
        fill: var(--input-hover);
      }
      .selected-store {
        color: var(--input-hover);
      }
    }
  }

  .toggle-container {
    position: relative;
    right: 20px;
    .toggle {
      position: relative;
      bottom: 5px;
      .toggle-pill {
        height: 6px;
        width: 20px;
        position: absolute;
        top: 2px;
        border: 1px solid var(--white);
        background-color: transparent;
      }
      .toggle-circle {
        height: 10px;
        width: 10px;
        position: absolute;
        left: 0;
        transition: 0.3s;
        border-radius: 100%;
        &[data-toggled='true'] {
          left: 10px;
        }
      }
    }
    &:hover {
      .pricing-type[data-has-both-types='true'] {
        color: var(--input-hover);
      }
      .toggle {
        .toggle-pill {
          border-color: var(--input-hover);
        }
        .toggle-circle {
          background-color: var(--input-hover);
        }
      }
    }
    &[data-has-toggle='false'] {
      right: 0;
      .pricing-type {
        margin: 0;
      }
    }
  }
}
